<template>
    <div class="reserve-table-container px-0 mx-0">
        <b-table
            responsive
            fixed
            no-local-sorting
            id="reserve-table"
            class="table-list"
            :items="orders"
            :fields="fields"
            :sort-by="camelCase(queryParams.sortBy)"
            :sort-desc="queryParams.sortOrder === 'desc'"
            v-on="$listeners"
        >
            <template #table-colgroup="scope">
                <col
                    v-for="field in scope.fields"
                    :key="field.key"
                    :style="{width: colWidths[field.key]}"
                />
            </template>
            <template #head(orderDate)="data">
                <div class="right-light-border">
                    <div class="d-flex column-explanation">
                        <div>
                            {{ data.label }}
                            <div class="mt-1 column-explanation-text">{{ t('dateHint') }}</div>
                        </div>
                    </div>
                </div>
            </template>
            <template #head(createdBy)="data">
                <div class="content">
                    <div class="d-flex column-explanation">
                        <div>
                            {{ data.label }}
                            <div class="mt-1 column-explanation-text">
                                {{ t('patientNameLastFirst') }}
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template #cell(orderHeaderId)="{item}">
                <div class="d-flex">
                    <div>
                        <custom-router-link
                            :to="{
                                name: isPreOrderListMode ? 'PreOrderDetails' : 'ReserveDetails',
                                params: {
                                    orderId: item.orderHeaderId,
                                },
                            }"
                            class="record-link text-primary mb-1"
                        >
                            {{ item.orderHeaderId }}
                        </custom-router-link>
                    </div>
                </div>
            </template>
            <template #cell(ocosPatientId)="{value, item}">
                <custom-router-link
                    :to="{
                        name: 'PatientDetails',
                        params: {patientId: item.patientId},
                    }"
                >
                    {{ value }}
                </custom-router-link>
            </template>
            <template #cell(opEye)="{value}">
                <b-img :src="imageSrc(value)" class="pr-1"></b-img>
            </template>
            <template #cell(selectedPrescription)="{value, item}">
                <div class="three-line-min">
                    <p class="text-nowrap text-caption heavy text-black mb-1 pl-0 ml-0">
                        {{
                            decimalSeparatorFormatter(
                                item.selectedModel,
                                currentUser.decimalSeparator
                            )
                        }}
                    </p>
                    <p class="text-caption light text-gray-dark mb-0">
                        {{ decimalSeparatorFormatter(value, currentUser.decimalSeparator) }}
                    </p>
                </div>
            </template>
            <template #cell(reservedPrescriptionDetails)="{value}">
                <div class="three-line-min">
                    <LensModel :lensDescriptionDetails="value" />
                    <LensPrescription :lensDescriptionDetails="value" />
                </div>
            </template>
            <template #cell(doctor)="{value}">
                <custom-router-link
                    :to="{
                        name: 'SurgeonDetails',
                        params: {doctorId: value.surgeonId},
                    }"
                    :disabled="!value.surgeonId"
                >
                    {{ value.contactPartyNumber }} - {{ value.firstName }} {{ value.lastName }}
                </custom-router-link>
            </template>
            <template #cell(status)="{value, item}">
                <span
                    :class="{
                        'text-warning': isDistributorMode && value === OrderSubStatus.PREORDER,
                    }"
                >
                    {{
                        getReservationOrderStatus(value, {isConsignment: isConsignment(item)})
                    }}</span
                >
                <br />
                <p
                    v-if="
                        [
                            OrderSubStatus.RESERVED,
                            OrderSubStatus.IN_CART,
                            OrderSubStatus.PREORDER,
                            OrderSubStatus.PREORDER_RESERVATION,
                        ].includes(value)
                    "
                    :class="[
                        'text-caption light mb-0',
                        {
                            'text-danger': item.expirationDateInWarningRange,
                            'text-gray-dark': !item.expirationDateInWarningRange,
                        },
                    ]"
                >
                    <span v-if="isConsignment(item)">{{ t('invLookup_Consignment') }}</span>
                    <span v-else class="white-space-pre"
                        >{{ t('short_expiration') }} {{ item.expirationDateTime | date }}</span
                    >
                </p>

                <custom-router-link
                    v-if="value == OrderSubStatus.ORDERED || value == OrderSubStatus.CONSUMED"
                    :to="{
                        name: 'OrderDetails',
                        params: {orderId: item.orderHeaderId},
                    }"
                    class="record-link text-primary mb-1"
                >
                    {{ item.orderNumber }}
                </custom-router-link>
            </template>
            <template #head(selected)="{}">
                <div class="right-light-border">
                    <div class="d-flex column-explanation no-wrap">
                        <span class="align-self-center" for="selected">
                            {{ t(isAllSelected ? 'unselectAll' : 'selectAll') }}</span
                        >
                        <b-form-checkbox
                            :checked="isAllSelected"
                            :disabled="!reserveItems.length"
                            @change="selectedChange"
                            class="ml-2"
                            name="selected"
                        />
                    </div>
                </div>
            </template>
            <template #cell(selected)="{item}">
                <b-form-checkbox
                    v-model="item.selected"
                    :disabled="!canAddToCart(item) || isConsignment(item)"
                    class="ml-2 text-center"
                />
            </template>
            <template #cell(actions)="{item}">
                <b-button-group size="sm">
                    <b-button
                        variant="white"
                        size="sm"
                        class="px-2"
                        v-b-tooltip.hover
                        :title="t('addToCart')"
                        @click="addToCart(item)"
                        v-if="canAddToCart(item) && !isConsignment(item)"
                    >
                        <b-icon icon="cart-plus-fill" scale="1.2" variant="primary"></b-icon>
                    </b-button>
                    <b-button
                        variant="white"
                        size="sm"
                        class="px-2"
                        v-b-tooltip.hover
                        :title="t('removeFromCart')"
                        @click="removeFromCart(item)"
                        v-if="canRemoveFromCart(item)"
                    >
                        <b-icon icon="cart-dash-fill" scale="1.2" variant="warning"></b-icon>
                    </b-button>
                    <b-button
                        variant="white"
                        size="sm"
                        class="px-2"
                        v-b-tooltip.hover
                        :title="t('cancelReservation')"
                        @click="promptCancel(item)"
                        v-if="canAddToCart(item)"
                    >
                        <b-icon icon="trash-fill" scale="1.2" variant="danger"></b-icon>
                    </b-button>
                </b-button-group>
            </template>
        </b-table>
        <LensPrompt
            :show.sync="showRemovePrompt"
            :lens="lens"
            :show-expiration-window="false"
            :removePromptKeyText="'reservationDetails_CancelReservationPrompt'"
            @on-remove="cancel()"
        />
    </div>
</template>

<script>
import camelCase from 'lodash/camelCase';
import get from 'lodash/get';
import {mapState, mapGetters} from 'vuex';
import date from '@/filters/date';
import ODIcon from '@/assets/od_icon.svg';
import OSIcon from '@/assets/os_icon.svg';
import {ITEM_TYPES} from '@/store/modules/cart';
import {OrderSubStatus} from '@/constants/order';
import LensPrompt from '@/components/LensPrompt.vue';
import {InventorySource} from '@/constants/inventory';
import {PERMISSIONS, PERMISSIONS_VALUES} from '@/constants/permissions';
import {decimalSeparatorFormatter, getReservationOrderStatus} from '@/utilities/formatters';
import {checkPermissions} from '@/utilities/permissions';
import LensPrescription from '@/components/LensPrescription';
import LensModel from '@/components/LensModel';

export default {
    components: {
        LensPrompt,
        LensModel,
        LensPrescription,
    },
    props: {
        queryParams: {
            type: Object,
            default: () => {},
        },
        isPreOrderListMode: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            ODIcon,
            OSIcon,
            OrderSubStatus,
            showRemovePrompt: false,
            colWidths: {
                //based on a 1536px width resolution screen
                selected: '120px',
                orderDate: '115px',
                createdBy: '168px',
                orderHeaderId: '112px',
                ocosPatientId: '150px',
                opEye: '70px',
                selectedPrescription: '178px',
                reservedPrescriptionDetails: '158px',
                status: '125px',
                doctor: '248px',
                actions: '90px',
            },
        };
    },
    computed: {
        ...mapState({
            orders: function (state) {
                state.reservations.list.forEach((item) => this.$set(item, 'selected', false));
                return state.reservations.list;
            },
            order: (state) => state.orders.selected,
            lens: (state) =>
                get(state, 'orders.selected.items', []).find(
                    (item) => item.itemType === ITEM_TYPES.LENS
                ),
        }),
        ...mapGetters('user', ['currentUser']),
        ...mapGetters('permissions', ['permissions']),
        canEdit() {
            return this.isPreOrderListMode
                ? false
                : this.checkPermissions({
                      [PERMISSIONS.RESERVATIONS]: PERMISSIONS_VALUES.READ_WRITE,
                  });
        },
        isDistributorMode() {
            return this.checkPermissions({
                [PERMISSIONS.DISTRIBUTOR_MODE]: [PERMISSIONS_VALUES.DISTRIBUTOR],
            });
        },
        fields() {
            const fields = [
                {
                    key: 'orderDate',
                    label: this.t(this.isPreOrderListMode ? 'preordered' : 'reserved'),
                    tdClass: 'text-caption text-gray-dark',
                    sortable: true,
                    formatter: (value) => date(value),
                },
                {key: 'createdBy', label: this.t('createdBy')},
                {key: 'orderHeaderId', label: this.t('number'), sortable: true},
                {key: 'ocosPatientId', label: this.t('patientId'), sortable: true},
                {key: 'opEye', label: this.t('lens')},
                {key: 'selectedPrescription', label: this.t('targetLens')},
                {
                    key: 'reservedPrescriptionDetails',
                    label: this.t(this.isPreOrderListMode ? 'preorderedLens' : 'reservedLens'),
                },
                {key: 'status', label: this.t('statusNormalCase'), sortable: true},
                {key: 'doctor', label: this.t('surgeon'), sortable: true},
            ];

            if (this.canEdit) {
                fields.push(
                    {key: 'selected', label: this.t('selected')},
                    {key: 'actions', label: this.t('actions'), sortable: false}
                );
            }

            return fields;
        },
        reserveItems() {
            return this.orders.filter(
                (item) => this.canAddToCart(item) && !this.isConsignment(item)
            );
        },
        selectedRows() {
            return this.orders.filter((item) => item.selected);
        },
        isAllSelected() {
            return this.selectedRows.length && this.reserveItems.every((item) => item.selected);
        },
    },
    methods: {
        camelCase,
        decimalSeparatorFormatter,
        getReservationOrderStatus,
        checkPermissions(permissionsToCheck) {
            return checkPermissions(
                permissionsToCheck,
                this.permissions,
                this.currentUser.accessPermissions
            );
        },
        imageSrc(opEye) {
            return this[`${opEye}Icon`];
        },
        selectedChange(value) {
            this.orders
                .filter((item) => this.canAddToCart(item) && !this.isConsignment(item))
                .forEach((item) => (item.selected = value));
        },
        isConsignment(lens) {
            return lens.lensSourceId == InventorySource.CONSIGNMENT;
        },
        canAddToCart(lens) {
            return (
                this.checkPermissions({[PERMISSIONS.ORDERING]: [PERMISSIONS_VALUES.READ_WRITE]}) &&
                [
                    OrderSubStatus.RESERVED,
                    OrderSubStatus.PREORDER,
                    OrderSubStatus.PREORDER_RESERVATION,
                ].includes(lens.status)
            );
        },
        canRemoveFromCart(lens) {
            return (
                this.checkPermissions({[PERMISSIONS.ORDERING]: [PERMISSIONS_VALUES.READ_WRITE]}) &&
                lens.status == OrderSubStatus.IN_CART &&
                lens.addedToCartByUserId === this.currentUser.userId
            );
        },
        async promptCancel(item) {
            await this.blockingRequest('orders/fetch', item.orderHeaderId);
            this.showRemovePrompt = true;
        },
        async cancel() {
            await this.updateOrder(this.order, 'cancel');
        },
        async addToCart(item) {
            const success = await this.updateOrder(item, 'addtocart');
            if (success) {
                await this.blockingRequest('cart/fetchItemsCount');
            }
        },
        async removeFromCart(item) {
            try {
                await this.blockingRequest('orders/fetch', item.orderHeaderId);
                const {success, error} = await this.blockingRequest(
                    'cart/removeItem',
                    this.lens.shoppingCartItemId
                );
                if (!success) throw error;
                this.$emit('record-changed', item);
                return success;
            } catch (error) {
                this.$bvToast.toast(
                    error?.errors?.join(' ') || 'Something went wrong removing lens from cart',
                    {
                        title: error?.errorCode || 'Unknown error',
                        variant: 'danger',
                        solid: true,
                    }
                );
            }
        },
        async updateOrder(item, status) {
            try {
                const {success, error} = await this.blockingRequest('orders/update', {
                    orderHeaderId: item.orderHeaderId,
                    status,
                });
                if (!success) throw error;
                this.$emit('record-changed', item);
                return success;
            } catch (error) {
                this.$bvToast.toast(
                    error?.errors?.join(' ') || 'Something went wrong updating the lens',
                    {
                        title: error?.errorCode || 'Unknown error',
                        variant: 'danger',
                        solid: true,
                    }
                );
            }
        },
    },
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/_variables.scss';

::v-deep .light-border-right {
    border-right: 1px solid $gray-light;
}
::v-deep .table-list > table > tbody > .table-active {
    background-color: $gray-light;
}
</style>
