var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.show,"no-wrap":"","fixed":""},scopedSlots:_vm._u([{key:"overlay",fn:function(){
var _obj;
return [_c('div',{ref:"dialog",staticClass:"text-center p-3 min-w-620",attrs:{"tabindex":"-1","role":"dialog","aria-modal":"false","aria-labelledby":"form-confirm-label"}},[_c('h3',[_c('strong',{attrs:{"id":"form-confirm-label"}},[_vm._v(" "+_vm._s(_vm.t(_vm.removePromptKeyText))+" ")])]),_vm._t("remove-prompt-before-card"),_c('b-card',[_c('b-row',{staticClass:"mb-2 text-center text-md-left"},[_c('b-col',{staticClass:"pr-4 pr-xl-0",attrs:{"md":"4","lg":"3"}},[_c('img',{staticClass:"mt-1",attrs:{"src":require("@/assets/shopping-cart/lens.svg")}})]),_c('b-col',{staticClass:"pl-3 pl-xl-0 d-flex justify-content-between",attrs:{"md":"8","lg":"9"}},[_c('div',[_c('h5',{staticClass:"mt-1 mb-1 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.lens.description)+" ")]),(_vm.canShowPrice)?_c('h6',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(_vm.lens.price))+" ")]):_vm._e(),_c('h6',[_c('span',{class:{'mr-2': _vm.lens.serial}},[_vm._v(_vm._s(_vm.lens.serial))]),_vm._v(" "+_vm._s(_vm.lens.modelNumber)+" ")]),_c('h6',[_vm._v(_vm._s(_vm.prescription))]),_c('h6',[_vm._v(" "+_vm._s(_vm.t('checkout_Patient'))+": "),_c(_vm.canGoPreopData ? 'router-link' : 'span',{tag:"custom-router-link",staticClass:"text-primary font-weight-bold",attrs:{"to":{
                                        name: 'PreOpData',
                                        params: {
                                            patientId: _vm.lens.patientId,
                                            preOpDataSetId: _vm.lens.preOpDataSetId,
                                        },
                                    }}},[_c('u',[_vm._v(_vm._s(_vm.lens.patientOcosId)+" - "+_vm._s(_vm.lens.opEye))])])],1),(_vm.lens.doctorId)?_c('h6',[_vm._v(" "+_vm._s(_vm.t('surgeon'))+": "),_c('custom-router-link',{staticClass:"text-primary font-weight-bold",attrs:{"to":{
                                        name: 'SurgeonDetails',
                                        params: {doctorId: _vm.lens.doctorId},
                                    }}},[_c('u',[_vm._v(_vm._s(_vm.lens.contactPartyNumber)+" - "+_vm._s(_vm.lens.doctorName))])])],1):_vm._e()]),_c('div',{staticClass:"text-right"},[_c('h6',{staticClass:"mt-2 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.t(_vm.isConsignment ? 'consignment_NameCaps' : 'staarName'))+" ")]),_c('h5',[_vm._t("badge")],2),_c('h6',{staticClass:"float-right",class:( _obj = {}, _obj['hidden-element'] = !_vm.showExpirationWindow, _obj )},[_c('span',{staticClass:"ml-3 text-danger"},[_vm._v(" "+_vm._s(_vm.lens.daysTillExpiration)+" "+_vm._s(_vm.t('checkout_DaysRemaining'))+" ")])])])])],1)],1),_vm._t("remove-prompt-after-card"),_c('div',{staticClass:"d-flex center mt-3"},[_c('b-button',{staticClass:"mr-3",attrs:{"variant":"outline-danger","size":"lg"},on:{"click":function($event){return _vm.$emit('update:show', false)}}},[_vm._v(" "+_vm._s(_vm.t('no'))+" ")]),_c('b-button',{attrs:{"variant":"outline-success","size":"lg","disabled":_vm.isConfirmButttonDisabled},on:{"click":_vm.onConfirmRemove}},[_vm._v(_vm._s(_vm.t('yes')))])],1)],2)]},proxy:true}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }