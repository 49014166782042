<template>
    <b-overlay :show="show" no-wrap fixed>
        <template #overlay>
            <div
                ref="dialog"
                tabindex="-1"
                role="dialog"
                aria-modal="false"
                aria-labelledby="form-confirm-label"
                class="text-center p-3 min-w-620"
            >
                <h3>
                    <strong id="form-confirm-label">
                        {{ t(removePromptKeyText) }}
                    </strong>
                </h3>
                <slot name="remove-prompt-before-card"></slot>
                <b-card>
                    <b-row class="mb-2 text-center text-md-left">
                        <b-col md="4" lg="3" class="pr-4 pr-xl-0">
                            <img class="mt-1" src="@/assets/shopping-cart/lens.svg"
                        /></b-col>
                        <b-col md="8" lg="9" class="pl-3 pl-xl-0 d-flex justify-content-between">
                            <div>
                                <h5 class="mt-1 mb-1 font-weight-bold">
                                    {{ lens.description }}
                                </h5>
                                <h6 v-if="canShowPrice" class="font-weight-bold">
                                    {{ lens.price | toCurrency }}
                                </h6>
                                <h6>
                                    <span :class="{'mr-2': lens.serial}">{{ lens.serial }}</span>
                                    {{ lens.modelNumber }}
                                </h6>
                                <h6>{{ prescription }}</h6>
                                <h6>
                                    {{ t('checkout_Patient') }}:
                                    <custom-router-link
                                        :to="{
                                            name: 'PreOpData',
                                            params: {
                                                patientId: lens.patientId,
                                                preOpDataSetId: lens.preOpDataSetId,
                                            },
                                        }"
                                        :is="canGoPreopData ? 'router-link' : 'span'"
                                        class="text-primary font-weight-bold"
                                    >
                                        <u>{{ lens.patientOcosId }} - {{ lens.opEye }}</u>
                                    </custom-router-link>
                                </h6>
                                <h6 v-if="lens.doctorId">
                                    {{ t('surgeon') }}:
                                    <custom-router-link
                                        :to="{
                                            name: 'SurgeonDetails',
                                            params: {doctorId: lens.doctorId},
                                        }"
                                        class="text-primary font-weight-bold"
                                    >
                                        <u>{{ lens.contactPartyNumber }} - {{ lens.doctorName }}</u>
                                    </custom-router-link>
                                </h6>
                            </div>
                            <div class="text-right">
                                <h6 class="mt-2 font-weight-bold">
                                    {{ t(isConsignment ? 'consignment_NameCaps' : 'staarName') }}
                                </h6>
                                <h5>
                                    <slot name="badge"></slot>
                                </h5>
                                <h6
                                    class="float-right"
                                    :class="{
                                        ['hidden-element']: !showExpirationWindow,
                                    }"
                                >
                                    <span class="ml-3 text-danger">
                                        {{ lens.daysTillExpiration }}
                                        {{ t('checkout_DaysRemaining') }}
                                    </span>
                                </h6>
                            </div>
                        </b-col>
                    </b-row>
                </b-card>
                <slot name="remove-prompt-after-card"></slot>
                <div class="d-flex center mt-3">
                    <b-button
                        variant="outline-danger"
                        size="lg"
                        class="mr-3"
                        @click="$emit('update:show', false)"
                    >
                        {{ t('no') }}
                    </b-button>
                    <b-button
                        variant="outline-success"
                        size="lg"
                        :disabled="isConfirmButttonDisabled"
                        @click="onConfirmRemove"
                        >{{ t('yes') }}</b-button
                    >
                </div>
            </div>
        </template>
    </b-overlay>
</template>

<script>
import {mapGetters} from 'vuex';
import {checkPermissions} from '@/utilities/permissions';
import {PERMISSIONS, PERMISSIONS_VALUES} from '@/constants/permissions';
import {decimalSeparatorFormatter} from '@/utilities/formatters';
import {InventorySource} from '@/constants/inventory';

export default {
    name: 'LensPrompt',
    components: {},
    props: {
        lens: {
            type: Object,
            default: () => {},
        },
        show: {
            type: Boolean,
            default: false,
        },
        showExpirationWindow: {
            type: Boolean,
            default: true,
        },
        removePromptKeyText: {
            type: String,
            default: 'checkout_RemoveLens',
        },
        isConfirmButttonDisabled: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters('user', ['currentUser']),
        ...mapGetters('permissions', ['permissions']),
        isDistributorSurgeonMode() {
            return this.checkPermissions({
                [PERMISSIONS.DISTRIBUTOR_MODE]: [PERMISSIONS_VALUES.SURGEON],
            });
        },
        isConsignment() {
            return this.lens.lensSourceId == InventorySource.CONSIGNMENT;
        },
        prescription() {
            return decimalSeparatorFormatter(
                this.lens.prescription,
                this.currentUser.decimalSeparator
            );
        },
        canGoPreopData() {
            return this.checkPermissions({
                [PERMISSIONS.PATIENT_PREOP_DATA]: [
                    PERMISSIONS_VALUES.READ_WRITE,
                    PERMISSIONS_VALUES.READ_ONLY,
                ],
            });
        },
        canShowPrice() {
            return !this.isDistributorSurgeonMode && !this.isConsignment;
        },
    },
    methods: {
        checkPermissions(permissionsToCheck) {
            return checkPermissions(
                permissionsToCheck,
                this.permissions,
                this.currentUser.accessPermissions
            );
        },
        async onConfirmRemove() {
            this.$emit('update:show', false);
            this.$emit('on-remove', this.lens);
        },
    },
};
</script>
<style lang="scss" scoped>
.min-w-620 {
    min-width: 620px;
}
</style>
